// Generated by Framer (79d07c3)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-6KM9l"

const variantClassNames = {aAkEvyvWe: "framer-v-icrq48"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "aAkEvyvWe", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>

</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6KM9l.framer-1r0et65, .framer-6KM9l .framer-1r0et65 { display: block; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 288
 * @framerIntrinsicWidth 1894
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrEXB_BAtk: React.ComponentType<Props> = withCSS(Component, css, "framer-6KM9l") as typeof Component;
export default FramerrEXB_BAtk;

FramerrEXB_BAtk.displayName = "Hero Ticker";

FramerrEXB_BAtk.defaultProps = {height: 288, width: 1894};

addFonts(FramerrEXB_BAtk, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})